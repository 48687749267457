import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"

export default ({ data }) => {
    const post = data.markdownRemark
    return (
        <Layout>
            <Helmet>
                <html lang="en" />
                <meta charSet="utf-8" />
                <meta name="description" content={post.excerpt} />
                <title>{post.frontmatter.title}</title>
            </Helmet>
            <div>
                <h1>{post.frontmatter.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
            excerpt
        }
    }
`